<template>
  <div id="outPassenger" class="wrap-Main">
    <div class="box-S4 flex-between-center">
      <div class="N-Page T-size-24">Outbound Passengers</div>
      <div class="b-numPassenger">
        <span class="icon I-passengers"></span>
        <div class="T-size-20 SemiBold">{{ sumPassenger }} Passengers</div>
      </div>
    </div>

    <!-- showAsGrid GRID -->
    <div class="box-S4" v-if="showAsGrid">
      <v-expansion-panels accordion class="b-accod" v-model="panel" multiple>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <span class="panal-title T-size-20">Passenger List</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="wrap-passenger">
              <div
                class="box-S1-3"
                v-for="(item, index) in listPassenger"
                :key="item.tsj_id"
              >
                <div
                  class="B-passenger"
                  :class="item.retire"
                  v-show="
                    item.MedicalCheckupDayLeft > '90' &&
                    item.SafetyTrainingDayLeft > '90'
                  "
                >
                  <!-- เพิ่ม Class update / delete เมื่อมีการส่งค่าว่าโดนแก้ไข หรือ ลบ -->
                  <div>
                    <div class="b-person">
                      <div class="b-display">
                        <div
                          class="B-display person1"
                          v-if="
                            item.u_imageurl == undefined ||
                            item.u_imageurl == null
                          "
                        ></div>
                        <div
                          class="B-display person1"
                          v-else
                          :style="{
                            backgroundImage:
                              'url(' + item.u_imageurl + ') !important',
                          }"
                        ></div>
                        <!-- เพิ่ม Class warning1 / warning2 เมื่อมี warning -->
                      </div>
                      <div class="b-name-company">
                        <p class="t-name T-size-14">{{ item.FullName }}</p>
                        <div class="t-company T-size-12">
                          Company : {{ item.c_name }}
                        </div>
                      </div>
                      <div class="b-action">
                        <div
                          class="b-btn"
                          v-if="
                            !(
                              item.tsj_picup_time_actual != null ||
                              item.tsj_drop_time_actual != null
                            )
                          "
                        >
                          <v-btn
                            fab
                            class="btn-edit"
                            @click="EditItem(item, index)"
                            :disabled="readonly_manament"
                          >
                            <span class="I-edit"></span>
                          </v-btn>
                          <v-btn
                            fab
                            class="primary btn-delete"
                            @click="DeleteItem(item)"
                            :disabled="readonly_manament"
                          >
                            <span class="I-bin"></span>
                          </v-btn>
                        </div>
                      </div>
                    </div>
                    <div class="b-detail">
                      <div class="box-S4 flex-between-center t-detail">
                        <div class="bL">
                          <span class="T-size-12">Telephone</span>
                          <p class="T-size-16">{{ item.u_telephone }}</p>
                        </div>
                      </div>
                    </div>
                    <div class="b-detail">
                      <div class="t-title">Destination</div>
                      <div class="box-S4 flex-between-center t-detail">
                        <div class="bL">
                          <span class="T-size-12">Pickup</span>
                          <p class="T-size-16">{{ item.pickup }}</p>
                          <p class="T-size-16">
                            <span v-if="item.tsj_picup_time != null">{{
                              item.tsj_picup_time
                            }}</span
                            ><span v-if="item.tsj_picup_time_actual != null">
                              / {{ item.tsj_picup_time_actual }}</span
                            >
                          </p>
                        </div>
                        <div class="bR">
                          <span class="T-size-12">Drop</span>
                          <p class="T-size-16">{{ item.drop }}</p>
                          <p class="T-size-16">
                            <span v-if="item.tsj_drop_time != null">{{
                              item.tsj_drop_time
                            }}</span
                            ><span v-if="item.tsj_drop_time_actual != null">
                              / {{ item.tsj_drop_time_actual }}</span
                            >
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="b-recover">
                    <v-btn
                      class="theme-btn-even btn-recover"
                      @click="Recover(item, index)"
                    >
                      <span class="T-size-18">Recover</span>
                    </v-btn>
                  </div>
                </div>

                <div
                  class="B-passenger expired"
                  :class="item.retire"
                  v-show="
                    item.MedicalCheckupDayLeft <= '90' ||
                    item.SafetyTrainingDayLeft <= '90'
                  "
                >
                  <!-- เพิ่ม Class update / delete เมื่อมีการส่งค่าว่าโดนแก้ไข หรือ ลบ -->
                  <div>
                    <div class="b-person">
                      <div class="b-display">
                        <div
                          class="B-display person1"
                          v-if="
                            item.u_imageurl == undefined ||
                            item.u_imageurl == null
                          "
                        ></div>
                        <div
                          class="B-display person1"
                          v-else
                          :style="{
                            backgroundImage:
                              'url(' + item.u_imageurl + ') !important',
                          }"
                        ></div>
                        <!-- เพิ่ม Class warning1 / warning2 เมื่อมี warning -->
                      </div>
                      <div class="b-name-company">
                        <p class="t-name T-size-14">{{ item.FullName }}</p>
                        <!-- Add -->
                        <div class="red--text">
                          <v-icon
                            class="subheading T-size-18"
                            v-show="
                              $CheckWarning.wranningSafetyTraining(
                                item.SafetyTrainingDayLeft
                              )
                            "
                            color="red"
                            >mdi-clock-alert</v-icon
                          >
                          <span class="T-size-12">
                            &nbsp;{{
                              $CheckWarning.wranningSafetyTraining(
                                item.SafetyTrainingDayLeft
                              )
                            }}</span
                          >
                        </div>
                        <div class="red--text">
                          <v-icon
                            class="subheading T-size-18"
                            v-show="
                              $CheckWarning.wranningMedicalCheckup(
                                item.MedicalCheckupDayLeft
                              )
                            "
                            color="red"
                            >mdi-clock-alert</v-icon
                          >
                          <span class="T-size-12">
                            &nbsp;{{
                              $CheckWarning.wranningMedicalCheckup(
                                item.MedicalCheckupDayLeft
                              )
                            }}</span
                          >
                        </div>
                        <div class="t-company T-size-12">
                          Company : {{ item.c_name }}
                        </div>
                      </div>
                      <div class="b-action">
                        <div
                          class="b-btn"
                          v-if="
                            !(
                              item.tsj_picup_time_actual != null ||
                              item.tsj_drop_time_actual != null
                            )
                          "
                        >
                          <v-btn
                            fab
                            class="btn-edit"
                            @click="EditItem(item, index)"
                            :disabled="readonly_manament"
                          >
                            <span class="I-edit"></span>
                          </v-btn>
                          <v-btn
                            fab
                            class="primary btn-delete"
                            @click="DeleteItem(item)"
                            :disabled="readonly_manament"
                          >
                            <span class="I-bin"></span>
                          </v-btn>
                        </div>
                      </div>
                    </div>
                    <div class="b-detail">
                      <div class="box-S4 flex-between-center t-detail">
                        <div class="bL">
                          <span class="T-size-12">Telephone</span>
                          <p class="T-size-16">{{ item.u_telephone }}</p>
                        </div>
                      </div>
                    </div>
                    <div class="b-detail">
                      <div class="t-title">Destination</div>
                      <div class="box-S4 flex-between-center t-detail">
                        <div class="bL">
                          <span class="T-size-12">Pickup</span>
                          <p class="T-size-16">{{ item.pickup }}</p>
                          <p class="T-size-16">
                            <span v-if="item.tsj_picup_time != null">{{
                              item.tsj_picup_time
                            }}</span
                            ><span v-if="item.tsj_picup_time_actual != null">
                              / {{ item.tsj_picup_time_actual }}</span
                            >
                          </p>
                        </div>
                        <div class="bR">
                          <span class="T-size-12">Drop</span>
                          <p class="T-size-16">{{ item.drop }}</p>
                          <p class="T-size-16">
                            <span v-if="item.tsj_drop_time != null">{{
                              item.tsj_drop_time
                            }}</span
                            ><span v-if="item.tsj_drop_time_actual != null">
                              / {{ item.tsj_drop_time_actual }}</span
                            >
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="b-recover">
                    <v-btn
                      class="theme-btn-even btn-recover"
                      @click="Recover(item, index)"
                    >
                      <span class="T-size-18">Recover</span>
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>

    <!-- showAsGrid ROW -->
    <div class="box-S4" v-if="!showAsGrid">

      <v-expansion-panels accordion class="b-accod" v-model="panel" multiple>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <span class="panal-title T-size-20">Passenger List</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div
              class="box-4"
              v-for="(item, index) in listPassenger"
              :key="item.tsj_id"
            >
              <div
                v-show="
                  item.MedicalCheckupDayLeft > '90' &&
                  item.SafetyTrainingDayLeft > '90'
                "
              >
                <div class="B-passenger TF">
                  <div class="b-person">
                    <div class="box-S1 fix-header">
                      <div
                        v-if="
                          item.u_imageurl == undefined ||
                          item.u_imageurl == null
                        "
                      ></div>
                      <div
                        class="B-display person1"
                        v-else
                        :style="{
                          backgroundImage:
                            'url(' + item.u_imageurl + ') !important',
                        }"
                      ></div>
                    </div>

                    <div class="box-S1">
                      <div class="b-name-company">
                        <p class="t-name T-size-14">{{ item.FullName }}</p>
                        <div class="t-company T-size-12">
                          {{ customer }} <span>[{{ item.c_name }}]</span>
                        </div>
                      </div>
                    </div>

                    <div class="box-S1 flex-between-center t-detail">
                      <div class="bC">
                        <span class="T-size-12">Telephone</span>
                        <p v-if="item.u_telephone != null" class="T-size-16">
                          {{ item.u_telephone }}
                        </p>
                        <p v-else class="T-size-16">-</p>
                      </div>
                    </div>

                    <div class="box-S1 flex-between-center t-detail">
                      <div class="bC">
                        <span class="T-size-12">Pickup</span>
                        <p class="T-size-16">{{ item.pickup }}</p>
                      </div>
                    </div>

                    <div class="box-S1 flex-between-center t-detail">
                      <div class="bC">
                        <span class="T-size-12">Pickup Time</span>
                        <p class="T-size-16">
                          <span v-if="item.tsj_picup_time != null">{{
                            item.tsj_picup_time
                          }}</span
                          ><span v-if="item.tsj_picup_time_actual != null">
                            / {{ item.tsj_picup_time_actual }}</span
                          >
                        </p>
                      </div>
                    </div>

                    <div class="box-S1 flex-between-center t-detail">
                      <div class="bC">
                        <span class="T-size-12">Drop</span>
                        <p class="T-size-16">{{ item.drop }}</p>
                      </div>
                    </div>

                    <div class="box-S1 flex-between-center t-detail">
                      <div class="bC">
                        <span class="T-size-12">Drop Time</span>
                        <p class="T-size-16">
                          <span v-if="item.tsj_drop_time != null">{{
                            item.tsj_drop_time
                          }}</span
                          ><span v-if="item.tsj_drop_time_actual != null">
                            / {{ item.tsj_drop_time_actual }}</span
                          >
                        </p>
                      </div>
                    </div>

                    <div class="box-S1 flex-between-center t-detail">
                      <div class="bC">
                        <span class="T-size-12">Vehicle</span>
                        <p v-if="item.vehicle != null" class="T-size-16">
                          {{ item.vehicle }}
                        </p>
                        <p v-else class="T-size-16">-</p>
                      </div>
                    </div>

                    <div class="box-S1 flex-between-center t-detail">
                      <div class="bC">
                        <span class="T-size-12">Driver</span>
                        <p v-if="item.driver != null" class="T-size-16">
                          {{ item.driver }}
                        </p>
                        <p v-else class="T-size-16">-</p>
                      </div>
                    </div>

                    <div class="b-action">
                      <div
                        class="b-btn"
                        v-if="
                          !(
                            item.tsj_picup_time_actual != null ||
                            item.tsj_drop_time_actual != null
                          )
                        "
                      >
                        <div class="b-btn">
                          <v-btn
                            fab
                            class="btn-edit"
                            @click="SelectVehicleAndDriverItem(item, index)"
                            v-show="
                              !(
                                readonly_eng ||
                                readonly_pilot ||
                                readonly_flight ||
                                readonly_shore ||
                                readonly_manament
                              )
                            "
                          >
                            <span class="I-car"></span>
                          </v-btn>
                        </div>
                      </div>
                    </div>

                    <div class="b-action">
                      <div
                        class="b-btn"
                        v-if="
                          !(
                            item.tsj_picup_time_actual != null ||
                            item.tsj_drop_time_actual != null
                          )
                        "
                      >
                        <v-btn
                          fab
                          class="btn-edit"
                          @click="EditItem(item, index)"
                          v-show="!(readonly_eng || readonly_pilot || readonly_flight)"
                          :disabled="readonly_manament">
                          <span class="I-edit"></span>
                        </v-btn>
                      </div>
                      <div v-else class="b-btn"></div>
                    </div>

                    <div class="b-action">
                      <div
                        class="b-btn"
                        v-if="
                          !(
                            item.tsj_picup_time_actual != null ||
                            item.tsj_drop_time_actual != null
                          )
                        "
                      >
                        <v-btn
                          fab
                          class="primary btn-delete"
                          @click="DeleteItem(item)"
                          v-show="
                            !(readonly_eng || readonly_pilot || readonly_flight)
                          "
                          :disabled="readonly_manament"
                        >
                          <span class="I-bin"></span>
                        </v-btn>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                v-show="
                  item.MedicalCheckupDayLeft <= '90' ||
                  item.SafetyTrainingDayLeft <= '90'
                "
              >
                <div class="B-passenger TF">
                  <div class="b-person">
                    <div class="box-S1 fix-header">
                      <div
                        v-if="
                          item.u_imageurl == undefined ||
                          item.u_imageurl == null
                        "
                      ></div>
                      <div
                        class="B-display person1"
                        v-else
                        :style="{
                          backgroundImage:
                            'url(' + item.u_imageurl + ') !important',
                        }"
                      ></div>
                    </div>

                    <div class="box-S1">
                      <div class="b-name-company">
                        <p class="t-name T-size-14">{{ item.FullName }}</p>

                        <!-- Add show only CPOC -->
                        <div class="red--text" v-show="customer === 'CPOC'">
                          <v-icon
                            class="subheading T-size-18"
                            v-show="
                              $CheckWarning.wranningSafetyTraining(
                                item.SafetyTrainingDayLeft
                              )
                            "
                            color="red"
                            >mdi-clock-alert</v-icon
                          >
                          <span class="T-size-12">
                            &nbsp;{{
                              $CheckWarning.wranningSafetyTraining(
                                item.SafetyTrainingDayLeft
                              )
                            }}</span
                          >
                        </div>
                        <div class="red--text" v-show="customer === 'CPOC'">
                          <v-icon
                            class="subheading T-size-18"
                            v-show="
                              $CheckWarning.wranningMedicalCheckup(
                                item.MedicalCheckupDayLeft
                              )
                            "
                            color="red"
                            >mdi-clock-alert</v-icon
                          >
                          <span class="T-size-12">
                            &nbsp;{{
                              $CheckWarning.wranningMedicalCheckup(
                                item.MedicalCheckupDayLeft
                              )
                            }}</span
                          >
                        </div>

                        <div class="t-company T-size-12">
                          {{ customer }} <span>[{{ item.c_name }}]</span>
                        </div>
                      </div>
                    </div>

                    <div class="box-S1 flex-between-center t-detail">
                      <div class="bC">
                        <span class="T-size-12">Telephone</span>
                        <p v-if="item.u_telephone != null" class="T-size-16">
                          {{ item.u_telephone }}
                        </p>
                        <p v-else class="T-size-16">-</p>
                      </div>
                    </div>

                    <div class="box-S1 flex-between-center t-detail">
                      <div class="bC">
                        <span class="T-size-12">Pickup</span>
                        <p class="T-size-16">{{ item.pickup }}</p>
                      </div>
                    </div>

                    <div class="box-S1 flex-between-center t-detail">
                      <div class="bC">
                        <span class="T-size-12">Pickup Time</span>
                        <p class="T-size-16">
                          <span v-if="item.tsj_picup_time != null">{{
                            item.tsj_picup_time
                          }}</span
                          ><span v-if="item.tsj_picup_time_actual != null">
                            / {{ item.tsj_picup_time_actual }}</span
                          >
                        </p>
                      </div>
                    </div>

                    <div class="box-S1 flex-between-center t-detail">
                      <div class="bC">
                        <span class="T-size-12">Drop</span>
                        <p class="T-size-16">{{ item.drop }}</p>
                      </div>
                    </div>

                    <div class="box-S1 flex-between-center t-detail">
                      <div class="bC">
                        <span class="T-size-12">Drop Time</span>
                        <p class="T-size-16">
                          <span v-if="item.tsj_drop_time != null">{{
                            item.tsj_drop_time
                          }}</span
                          ><span v-if="item.tsj_drop_time_actual != null">
                            / {{ item.tsj_drop_time_actual }}</span
                          >
                        </p>
                      </div>
                    </div>

                    <div class="box-S1 flex-between-center t-detail">
                      <div class="bC">
                        <span class="T-size-12">Vehicle</span>
                        <p v-if="item.vehicle != null" class="T-size-16">
                          {{ item.vehicle }}
                        </p>
                        <p v-else class="T-size-16">-</p>
                      </div>
                    </div>

                    <div class="box-S1 flex-between-center t-detail">
                      <div class="bC">
                        <span class="T-size-12">Driver</span>
                        <p v-if="item.driver != null" class="T-size-16">
                          {{ item.driver }}
                        </p>
                        <p v-else class="T-size-16">-</p>
                      </div>
                    </div>

                    <div class="b-action">
                      <div
                        class="b-btn"
                        v-if="
                          !(
                            item.tsj_picup_time_actual != null ||
                            item.tsj_drop_time_actual != null
                          )
                        "
                      >
                        <div class="b-btn">
                          <v-btn
                            fab
                            class="btn-edit"
                            @click="SelectVehicleAndDriverItem(item, index)"
                            v-show="
                              !(
                                readonly_eng ||
                                readonly_pilot ||
                                readonly_flight ||
                                readonly_shore ||
                                readonly_manament
                              )
                            "
                            :disabled="readonly_manament"
                          >
                            <span class="I-car"></span>
                          </v-btn>
                        </div>
                      </div>
                    </div>

                    <div class="b-action">
                      <div
                        class="b-btn"
                        v-if="
                          !(
                            item.tsj_picup_time_actual != null ||
                            item.tsj_drop_time_actual != null
                          )
                        "
                      >
                        <div class="b-btn">
                          <v-btn
                            fab
                            class="btn-edit"
                            @click="EditItem(item, index)"
                            v-show="
                              !(
                                readonly_eng ||
                                readonly_pilot ||
                                readonly_flight
                              )
                            "
                            :disabled="readonly_manament"
                          >
                            <span class="I-edit"></span>
                          </v-btn>
                        </div>
                      </div>
                    </div>

                    <div class="b-action">
                      <div
                        class="b-btn"
                        v-if="
                          !(
                            item.tsj_picup_time_actual != null ||
                            item.tsj_drop_time_actual != null
                          )
                        "
                      >
                        <div class="b-btn">
                          <v-btn
                            fab
                            class="primary btn-delete"
                            @click="DeleteItem(item)"
                            v-show="
                              !(
                                readonly_eng ||
                                readonly_pilot ||
                                readonly_flight
                              )
                            "
                            :disabled="readonly_manament"
                          >
                            <span class="I-bin"></span>
                          </v-btn>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>

    <!-- REVISE OUT -->
    <div class="box-S4 wrap-Main B-passenger" v-if="total > 0">
      <div class="box-S4 flex-between-center">
        <div>
          <div style="margin-bottom: 10px; color: #fb0000 !important">
            <v-icon style="color: #fb0000 !important">mdi-account-alert</v-icon>
            Warning Different {{ total }} Name (Booking Now)
          </div>
        </div>
        <v-btn
          v-if="userdata.r_id == 2 || userdata.r_id == 4 || userdata.r_id == 8"
          class="right"
          flat
          color="primary"
          @click="syncDataOut()"
          style="margin-top: -10px"
        >
          <v-icon>mdi-sync</v-icon>Sync Passenger
        </v-btn>
      </div>
      <div class="box-S4 noPadding">
        <v-data-table
          :headers="headersRevise"
          :items="dataCompareOut"
          hide-actions
          class="test"
        >
          <template slot="headerCell" slot-scope="{ header }">
            <span
              class="subheading font-weight-light text--darken-3"
              v-text="header.text"
            />
          </template>
        </v-data-table>
      </div>
    </div>
    <!-- REVISE OUT -->

    <div class="box-S1-3">
      <v-btn
        class="theme-btn-even MGB10"
        @click="AddPassenger()"
        v-if="userdata.r_id == 2 || userdata.r_id == 8"
      >
        <span class="I-create"></span>
        <span>Add</span>
      </v-btn>
    </div>
    <!-- showAsGrid button -->
    <div class="box-S1">
      <v-switch v-model="showAsGrid" :label="`GRID`"> </v-switch>
    </div>
    <div class="line"></div>
    <v-dialog v-model="dialogEvent" persistent max-width="400">
      <v-card id="dialog" class="TF type2">
        <div class="b-content">
          <!-- Start step 1 select passenger -->
          <div class="B-dialogform step">
            <div class="t-namepage">{{ formTitle }} Passenger</div>
            <div class="b-form">
              <v-stepper v-model="stepdialog">
                <v-stepper-header>
                  <v-stepper-step
                    :complete="stepdialog > 1"
                    step="1"
                  ></v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step step="2"></v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                  <!-- Start step 1 select passenger -->
                  <v-stepper-content step="1">
                    <div class="b-contentstep">
                      <div
                        class="box-btn-select"
                        v-show="arraychoospassenger[0].FullName == ''"
                      >
                        <v-btn
                          class="theme-btn-even btn-select"
                          @click="OpenDialogSelectPassenger()"
                        >
                          <span class="I-create"></span>
                          <span>Select Passenger</span>
                        </v-btn>
                      </div>
                      <div class="B-passenger TF">
                        <div v-show="arraychoospassenger[0].FullName != ''">
                          <div class="b-person">
                            <div class="b-display">
                              <div
                                class="B-display person1"
                                :style="{
                                  backgroundImage:
                                    'url(' +
                                    arraychoospassenger[0].u_imageurl +
                                    ') !important',
                                }"
                              ></div>
                            </div>
                            <div class="b-name-company">
                              <p class="t-name">
                                {{ arraychoospassenger[0].FullName }}
                              </p>
                              <div class="t-company T-size-12">
                                Company : {{ arraychoospassenger[0].c_name }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="box-btn">
                      <v-btn
                        class="theme-btn-even btn-cancel Bsize100"
                        text
                        @click="dialogEvent = false"
                      >
                        <span class="T-size-18">cancel</span>
                      </v-btn>
                      <!-- <v-btn
                        class="theme-btn-even btn-ok Bsize100"
                        @click="stepdialog = 2"
                      >
                        <span class="T-size-18">next</span>
                      </v-btn> -->
                      <!-- Edit -->
                      <v-btn
                        class="theme-btn-even btn-ok Bsize100"
                        @click="GotoStepDialog2()"
                      >
                        <span class="T-size-18">next</span>
                      </v-btn>
                    </div>
                  </v-stepper-content>
                  <!-- End step 1 select passenger -->

                  <v-stepper-content step="2">
                    <div class="b-contentstep">
                      <div class="B-passenger TF">
                        <div class="b-detail">
                          <div class="t-title">Location</div>
                          <div class="box-S4 flex-between-center">
                            <div class="box-S2 t-detail">
                              <v-select
                                class="hideMessage"
                                v-model="pickup"
                                :items="itemsListTransport"
                                label="Pick Up"
                                item-text="tsl_name"
                                item-value="tsl_id"
                                return-object
                              ></v-select>
                              <v-text-field
                                append-icon="mdi-clock-outline"
                                v-model="tsj_picup_time"
                                label="Time"
                                type="time"
                              ></v-text-field>
                            </div>
                            <div class="box-S2 t-detail">
                              <v-select
                                class="hideMessage"
                                v-model="drop"
                                :items="itemsListTransport"
                                label="Drop"
                                item-text="tsl_name"
                                item-value="tsl_id"
                                return-object
                              ></v-select>
                              <v-text-field
                                append-icon="mdi-clock-outline"
                                v-model="tsj_drop_time"
                                label="Time"
                                type="time"
                              ></v-text-field>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="box-btn">
                      <v-btn
                        class="theme-btn-even btn-cancel Bsize100"
                        text
                        @click="stepdialog = 1"
                      >
                        <span class="T-size-18">cancel</span>
                      </v-btn>
                      <v-btn
                        class="theme-btn-even btn-save Bsize100"
                        @click="SaveToArrayPassenger()"
                      >
                        <span class="T-size-18">Finish</span>
                      </v-btn>
                    </div>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            </div>
          </div>
          <!-- End step 1 select passenger -->
        </div>
      </v-card>
    </v-dialog>
    <!-- Select Passenger -->
    <v-dialog v-model="dialogSelectPassenger" persistent max-width="800" fullscreen>
      <v-card id="dialog" class="TF type2">
        <div class="b-content">
          <div class="box-S4 flex-between-center">
            <div class="N-Page T-size-36 TF">Select Passenger</div>
            <v-btn
              class="theme-btn-even"
              @click="dialogSelectPassenger = false"
            >
              <span>Close</span>
            </v-btn>
          </div>
          <div class="box-S4">
            <v-text-field
              v-model="search"
              class="hideMessage"
              label="Search by firstname"
              v-on:keyup.enter="SearchPassenger()"
            ></v-text-field>

            <div class="b-action">
              <div class="b-btn">
                <v-btn @click="SearchPassenger()">
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
          <div class="box-S4 noPadding flex-between-center">
            <!-- <v-data-table
              :headers="headers"
              :search="search"
              :items="userItem"
              :items-per-page="5"
            > -->
            <v-data-table
            class="y-scroll"
              :headers="headers"
              :items="userItem"
              :items-per-page="5"
            >
              <template v-slot:item.action="{ item }">
                <div class="b-action">
                  <div class="b-btn">
                    <v-btn fab class="btn-edit" @click="ChoosePassenger(item)"
                      ><span class="I-add2"></span
                    ></v-btn>
                  </div>
                </div>
              </template>
              <template v-slot:item.u_imageurl="{ item }">
                <img class="B-display In-table" :src="item.u_imageurl" />
              </template>
            </v-data-table>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogEvent2" persistent max-width="400">
      <v-card id="dialog" class="TF type2">
        <div class="b-content">
          <!-- Start step 1 select passenger -->
          <div class="B-dialogform step">
            <div class="t-namepage">Select Vehicle and Driver</div>
            <div class="b-form">
              <v-stepper v-model="stepdialog2">
                <v-stepper-items>
                  <v-stepper-content step="2">
                    <div class="b-contentstep">
                      <div class="B-passenger TF">
                        <div class="b-detail">
                          <!-- <div class="t-title">Location</div> -->
                          <div class="box-S4 flex-between-center">
                            <div class="box-S2 t-detail">
                              <v-select
                                class="hideMessage"
                                v-model="vehicle"
                                :items="itemsListVehicle"
                                label="Vehicle"
                                item-text="v_license_plate"
                                item-value="v_id"
                                return-object
                              ></v-select>
                            </div>
                            <div class="box-S3 t-detail">
                              <v-select
                                class="hideMessage"
                                v-model="driver"
                                :items="itemsListDriver"
                                label="Driver"
                                item-text="d_name"
                                item-value="d_id"
                                return-object
                              ></v-select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="box-btn">
                      <v-btn
                        class="theme-btn-even btn-cancel Bsize100"
                        text
                        @click="dialogEvent2 = false"
                      >
                        <span class="T-size-18">cancel</span>
                      </v-btn>
                      <v-btn
                        class="theme-btn-even btn-save Bsize100"
                        @click="SaveVehicleAndDriver()"
                      >
                        <span class="T-size-18">Finish</span>
                      </v-btn>
                    </div>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            </div>
          </div>
          <!-- End step 1 select passenger -->
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import feathersClientUOA from "../../plugins/feathers-client-uoa";
import feathersClientVan from "../../plugins/feathers-client";

import { startOfDay, endOfDay, format } from "date-fns";
import fillerdate from "../../utils/filterdate";

export default {
  data: () => ({
    dataCompareOut: [],
    total: 0,
    headersRevise: [
      { value: "Status", text: "Status", sortable: true },
      { value: "Name", text: "Name", sortable: true },
    ],

    vehicle: null,
    driver: null,

    showAsGrid: false,

    readonly_flight: false,
    readonly_pilot: false,
    readonly_eng: false,
    readonly_shore: false,
    readonly_manament: true,

    userdata: "",
    modeEvent: "",
    dialogEvent: false,
    dialogEvent2: false,
    dialogSelectPassenger: false,
    stepdialog: 1,
    stepdialog2: 1,
    headers: [
      { value: "u_imageurl", text: "", sortable: false },
      // { value: "u_staffcode", text: "Staff Code", sortable: false },
      { value: "u_firstname", text: "First name", sortable: true },
      { value: "u_lastname", text: "Last name", sortable: true },
      // { value: "u_username", text: "Username", sortable: false },
      { value: "r_name", text: "Position", sortable: false },
      { value: "c_name", text: "Company", sortable: false },
      { value: "action", text: "", sortable: false },
    ],
    search: "",
    itemsListTransport: [],
    itemsListDriver: [],
    itemsListVehicle: [],
    // input Model

    date: new Date(),
    remark: "",

    pickup: "",
    pickupname: "",

    drop: "",
    dropname: "",

    tsj_picup_time: "",
    tsj_drop_time: "",
    idtransport: "",
    listPassenger: [],
    itemsDataPassenger: [],
    arraychoospassenger: [{ FullName: "", c_name: "", u_imageurl: "" }],
    chooseItemPassengerIndex: null,
    chooseItemPassenger: null,
    menuselecttimepicup: false,
    menuselecttimedrop: false,
    userItem: [],
    panel: [0],
    retire: "",

    //Add
    safetyTrainingDayLeft: 0,
    medicalCheckupDayLeft: 0,

    //Add
    sumPassenger: 0,
  }),
  props: ["customer", "transportId"],
  components: {},
  async mounted() {
    this.userdata = this.$CurrentUser.GetCurrentUser();
    this.RenderDataSelectUser();
    this.RenderDataSelectTransport();
    this.SelectListVehicle();
    this.SelectListDriver();
    this.enable_disable();

    this.RenderUI();
  },
  computed: {
    formTitle() {
      return this.modeEvent === "Create" ? "Add" : "Edit";
    },
  },
  methods: {
    async RenderUI() {
      await this.ComparePassengerOut();
    },

    async ComparePassengerOut() {
      try {
        let a = await feathersClientUOA
          .service("transportcompareservice")
          .patch("ComparePassenger", { Id: this.transportId });
        this.dataCompareOut = a[0].datapassenger.filter(
          (x) => x.PassengerTypeId == 1
        );
        this.total = this.dataCompareOut.length;
        //console.log("dataCompareOut : " + JSON.stringify(this.dataCompareOut))
      } catch (err) {
        //alert("ไม่สามารถต่อ server ได้ Compare"+err);
      }
    },
    async syncDataOut() {
      try {
        await feathersClientUOA
          .service("transportcompareservice")
          .patch("SyncOutPassenger", { Id: this.transportId });
        await this.DataPassengerFromDB(this.transportId);
        this.ComparePassengerOut();
      } catch (err) {
        console.log(err);
        this.$toast.error("Cannot delete data " + err);
      }
    },

    async enable_disable() {
      if (this.userdata.r_id == 4) {
        this.readonly_flight = false;
      } else if (this.userdata.r_id == 9) {
        this.readonly_eng = true;
      } else if (this.userdata.r_id == 12) {
        this.readonly_pilot = true;
      } else if (this.userdata.r_id == 2) {
        this.readonly_shore = true;
      } else if (this.userdata.r_id == 15) {
        this.readonly_manament = true;
      }
    },
    //Add
    OpenDialogSelectPassenger() {
      this.search = "";
      this.userItem = [];
      this.dialogSelectPassenger = true;
    },
    //Add
    async SumDataPassenger() {
      this.sumPassenger = 0;
      this.listPassenger.forEach((element) => {
        //Add
        if (element.retire != "delete") {
          this.sumPassenger += 1;
        }
      });
    },
    async RenderDataSelectTransport() {
      try {
        var res = await feathersClientUOA.service("transportlocation").find({});
        this.itemsListTransport = res.data;
      } catch (error) {
        console.log(error);
        alert("ไม่สามารถขอข้อมูลจาก server ได้");
      }
    },
    async DataPassengerFromDB(idtransport) {
      this.itemsDataPassenger = [];
      this.listPassenger = [];
      try {
        var q = {};
        q.ts_id = idtransport;
        q.pt_id = 1;
        q.retire = 0;
        var res = await feathersClientUOA
          .service("viewtransportjob")
          .find({ query: q });
        this.itemsDataPassenger = res.data;
        this.itemsDataPassenger.forEach((element) => {
          var datachoosepassenger = {};
          datachoosepassenger["tsj_id"] = element.tsj_id;
          datachoosepassenger["u_id"] = element.u_id;
          datachoosepassenger["FullName"] =
            element.u_firstname + " " + element.u_lastname;

          datachoosepassenger["u_imageurl"] = element.u_imageurl;
          datachoosepassenger["c_name"] = element.c_name;
          datachoosepassenger["remark"] = element.tsj_remark;

          //datachoosepassenger["tsl_picup_name"] = element.tsl_picup_name;
          //Edit
          datachoosepassenger["tsl_pickup_id"] = element.tsl_picup_id;
          datachoosepassenger["pickup"] = element.tsl_picup_name;

          //datachoosepassenger["tsl_drop_name"] = element.tsl_drop_name;
          //Edit
          datachoosepassenger["tsl_drop_id"] = element.tsl_drop_id;
          datachoosepassenger["drop"] = element.tsl_drop_name;

          if (element.tsj_picup_time != null) {
            datachoosepassenger["tsj_picup_time"] =
              element.tsj_picup_time.substring(11, 16);
          }
          if (element.tsj_picup_time_actual != null) {
            datachoosepassenger["tsj_picup_time_actual"] =
              element.tsj_picup_time_actual.substring(11, 16);
          }

          if (element.tsj_drop_time != null) {
            datachoosepassenger["tsj_drop_time"] =
              element.tsj_drop_time.substring(11, 16);
          }
          if (element.tsj_drop_time_actual != null) {
            datachoosepassenger["tsj_drop_time_actual"] =
              element.tsj_drop_time_actual.substring(11, 16);
          }

          datachoosepassenger["u_telephone"] = element.u_telephone;
          datachoosepassenger["retire"] = element.retire == 1 ? "delete" : "";

          //Add
          //alert(element.SafetyTrainingDayLeft + "  " + element.MedicalCheckupDayLeft)
          datachoosepassenger["SafetyTrainingDayLeft"] =
            element.SafetyTrainingDayLeft;
          datachoosepassenger["MedicalCheckupDayLeft"] =
            element.MedicalCheckupDayLeft;

          datachoosepassenger["v_id"] = element.v_id;
          datachoosepassenger["vehicle"] = element.v_license_plate;
          datachoosepassenger["d_id"] = element.d_id;
          datachoosepassenger["driver"] = element.d_name;

          this.listPassenger.push(datachoosepassenger);
        });
        await this.SumDataPassenger();

        return this.listPassenger.length;
      } catch (error) {
        console.log(error);
      }
    },
    async DataPassengerFromBookingDB(idtransport) {
      try {
        var q = {};
        q.b_id = idtransport;
        q.pt_id = 1;
        q.retire = 0;
        var res = await feathersClientUOA
          .service("viewbookingpassenger")
          .find({ query: q });
        this.itemsDataPassenger = res.data;

        this.itemsDataPassenger.forEach((element) => {
          var datachoosepassenger = {};
          datachoosepassenger["bp_id"] = element.bp_id;
          datachoosepassenger["tsj_id"] = null;
          datachoosepassenger["u_id"] = element.u_id;
          datachoosepassenger["FullName"] =
            element.u_firstname + " " + element.u_lastname;

          datachoosepassenger["u_imageurl"] = element.u_imageurl;
          datachoosepassenger["c_name"] = element.c_name;
          datachoosepassenger["remark"] = element.bp_remark;
          datachoosepassenger["tsl_picup_id"] = "";
          datachoosepassenger["tsl_picup_name"] = "";
          datachoosepassenger["tsl_drop_id"] = "";
          datachoosepassenger["tsl_drop_name"] = "";
          datachoosepassenger["u_telephone"] = element.u_telephone;

          this.listPassenger.push(datachoosepassenger);
          this.ASCOrderbyPassenger();
          this.SumDataPassenger();
        });
      } catch (error) {
        console.log(error);
      }
    },

    async ASCOrderbyPassenger() {
      if (this.listPassenger.length > 0) {
        this.listPassenger.sort(function (a, b) {
          let start = a.bp_id;
          let end = b.bp_id;
          return start - end;
        });
      }
    },

    async RenderDataSelectUser() {
      try {
        const { user } = await feathersClientUOA.get("authentication");
        //User
        // this.search = "";
        // const q = {};
        // if (this.userdata.r_id != 4 && this.userdata.r_id != 8) {
        //   q.o_id = this.userdata.o_id;
        // }
        // q.r_id = 3;
        // let res = await feathersClientUOA
        //   .service("viewuser")
        //   .find({ query: q });
        // this.userItem = res.data;
      } catch (err) {}
    },
    async AddPassenger() {
      this.modeEvent = "create";
      this.dialogEvent = true;
      this.stepdialog = 1;
      this.arraychoospassenger = [
        { FullName: "", c_name: "", u_id: "", u_telephone: "", u_imageurl: "" },
      ];
      this.remark = "";
      this.pickup = "";
      this.drop = "";
      this.tsj_picup_time = "";
      this.tsj_drop_time = "";

      //Add Set default
      this.pickup = this.itemsListTransport[0];
      this.tsj_picup_time = "00:00:00";
      this.drop = this.itemsListTransport[0];
      this.tsj_drop_time = "00:00:00";
    },
    async EditItem(itemPass, index) {
      this.modeEvent = "edit";
      this.dialogEvent = true;
      this.stepdialog = 1;
      this.arraychoospassenger = [
        {
          FullName: itemPass.FullName,
          c_name: itemPass.c_name,
          u_id: itemPass.u_id,
          u_telephone: itemPass.u_telephone,
          u_imageurl: itemPass.u_imageurl,
        },
      ];
      this.u_telephone = itemPass.u_telephone;
      this.remark = itemPass.remark;

      //this.pickup = itemPass.tsl_picup_name;
      //Edit
      this.pickup = itemPass.tsl_pickup_id;
      this.pickupname = itemPass.pickup;
      this.tsj_picup_time = itemPass.tsj_picup_time;

      //this.drop = itemPass.tsl_drop_name;
      //Edit
      this.drop = itemPass.tsl_drop_id;
      this.dropname = itemPass.drop;
      this.tsj_drop_time = itemPass.tsj_drop_time;

      // this.tsj_picup_time = itemPass.tsj_picup_time;
      // this.tsj_drop_time = itemPass.tsj_drop_time;
      //Edit
      if (
        itemPass.tsj_picup_time != null &&
        itemPass.tsj_picup_time.length > 11
      ) {
        this.tsj_picup_time = itemPass.tsj_picup_time.substr(11, 8);
      }
      if (
        itemPass.tsj_drop_time != null &&
        itemPass.tsj_picup_time.length > 11
      ) {
        this.tsj_drop_time = itemPass.tsj_drop_time.substr(11, 8);
      }

      this.chooseItemPassengerIndex = index;

      //Add
      this.safetyTrainingDayLeft = itemPass.SafetyTrainingDayLeft;
      this.medicalCheckupDayLeft = itemPass.MedicalCheckupDayLeft;
    },
    async ChoosePassenger(dataUser) {
      this.dialogSelectPassenger = false;
      this.arraychoospassenger = [];
      this.arraychoospassenger.push(dataUser);
    },
    async SaveToArrayPassenger() {
      this.dialogEvent = false;
      if (this.modeEvent == "create") {
        //Add to transport job
        var DataAdd = {
          u_id: this.arraychoospassenger[0].u_id,
          ts_id: this.transportId,
          tsl_picup_id: this.pickup.tsl_id,
          tsj_picup_time: this.tsj_picup_time,
          tsl_drop_id: this.drop.tsl_id,
          tsj_drop_time: this.tsj_drop_time,
          pt_id: 1,
          tsj_remark: this.remark,
        };
        await this.CreateTransportJob(DataAdd);
      } else {
        //Edit to transport job
        let pickupId = null;
        if (this.pickup.tsl_id) {
          pickupId = this.pickup.tsl_id;
        } else {
          pickupId = this.pickup;
        }

        let dropId = null;
        if (this.drop.tsl_id) {
          dropId = this.drop.tsl_id;
        } else {
          dropId = this.drop;
        }

        let dataUpdate = {
          tsl_picup_id: pickupId,
          tsj_picup_time: this.tsj_picup_time,
          tsl_drop_id: dropId,
          tsj_drop_time: this.tsj_drop_time,
          tsj_remark: this.remark,
          retire: 0,
        };

        await this.Updatetodb(
          this.listPassenger[this.chooseItemPassengerIndex].tsj_id,
          dataUpdate
        );
      }
      await this.DataPassengerFromDB(this.transportId);
    },
    async DeleteItem(itemPass) {
      //Edit
      await feathersClientUOA.service("transportjob").remove(itemPass.tsj_id);
      await this.DataPassengerFromDB(this.transportId);
      this.ComparePassengerOut();
    },
    // async CreateTransportJobRawData(Id) {
    //   try {
    //     this.listPassenger.forEach((element) => {
    //       var DataAdd = {
    //         u_id: element.u_id,
    //         ts_id: Id,
    //         tsl_picup_id: element.tsl_picup_id,
    //         tsl_drop_id: element.tsl_drop_id,
    //         tsj_picup_time: this.tsj_picup_time,
    //         tsj_drop_time: this.tsj_drop_time,
    //         pt_id: 1,
    //         tsj_remark: element.remark,
    //       };
    //       if (element.tsj_id != null) {
    //         DataAdd.tsj_id = element.tsj_id;
    //       }

    //       this.CreateTransportJob(DataAdd);
    //     });
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },
    async CreateTransportJob(DataAdd) {
      try {
        var res = await feathersClientUOA
          .service("transportjob")
          .create(DataAdd);
      } catch (error) {
        console.log(error);
      }
    },

    // async SavePassengerEditToDB(idtransport) {
    //   this.idtransport = idtransport;
    //   var querytransport = { ts_id: this.idtransport, pt_id: 1 };
    //   var res = await feathersClientUOA
    //     .service("transportjob")
    //     .find({ query: querytransport });
    //   var DataAdd = {
    //     retire: 1,
    //   };
    //   res.data.forEach((element) => {
    //     this.Updatetodb(element.tsj_id, DataAdd);
    //   });

    //   this.listPassenger.forEach((elementUpdate) => {
    //     if (elementUpdate.tsj_id != null) {
    //       let dataUpdate = {
    //         // tsl_picup_id: elementUpdate.tsl_picup_id,
    //         //Edit
    //         tsl_picup_id: elementUpdate.tsl_pickup_id,

    //         tsl_drop_id: elementUpdate.tsl_drop_id,
    //         tsj_remark: elementUpdate.remark,
    //         tsj_picup_time: elementUpdate.tsj_picup_time,
    //         tsj_drop_time: elementUpdate.tsj_drop_time,
    //         retire: elementUpdate.retire == "delete" ? 1 : 0,
    //       };
    //       this.Updatetodb(elementUpdate.tsj_id, dataUpdate);
    //     } else if (elementUpdate.tsj_id == null) {
    //       var DataAdd = {
    //         u_id: elementUpdate.u_id,
    //         ts_id: this.idtransport,
    //         // tsl_picup_id: elementUpdate.tsl_picup_id,
    //         //Edit
    //         tsl_picup_id: elementUpdate.tsl_pickup_id,

    //         tsl_drop_id: elementUpdate.tsl_drop_id,
    //         tsj_picup_time: this.tsj_picup_time,
    //         tsj_drop_time: this.tsj_drop_time,
    //         pt_id: 1,
    //         tsj_remark: elementUpdate.remark,
    //       };
    //       this.CreateTransportJob(DataAdd);
    //     }
    //   });

    //   this.SumDataPassenger();
    // },

    async Updatetodb(idtransportjob, dataUpdate) {
      try {
        await feathersClientUOA
          .service("transportjob")
          .patch(idtransportjob, dataUpdate);
      } catch (error) {
        console.log(error);
      }
      this.SumDataPassenger();
    },
    async Recover(itemPass, index) {
      // this.modeEvent = "edit";
      // this.dialogEvent = true;
      // this.stepdialog = 1;
      // this.arraychoospassenger = [
      //   {
      //     FullName: itemPass.FullName,
      //     c_name: itemPass.c_name,
      //     u_id: itemPass.u_id,
      //     u_telephone: itemPass.u_telephone,
      //     u_imageurl:itemPass.u_imageurl
      //   }
      // ];
      // this.u_telephone = itemPass.u_telephone;
      // this.remark = itemPass.remark;
      // this.pickup = itemPass.tsl_picup_name;
      // this.drop = itemPass.tsl_drop_name;
      // this.tsj_picup_time = itemPass.tsj_picup_time;
      // this.tsj_drop_time = itemPass.tsj_drop_time;
      // this.chooseItemPassengerIndex = index;

      this.modeEvent = "edit";
      var datachoosepassenger = {};
      this.arraychoospassenger = [
        {
          FullName: itemPass.FullName,
          c_name: itemPass.c_name,
          u_id: itemPass.u_id,
          u_telephone: itemPass.u_telephone,
          u_imageurl: itemPass.u_imageurl,
        },
      ];
      datachoosepassenger["tsj_id"] = itemPass.tsj_id;
      datachoosepassenger["u_id"] = itemPass.u_id;
      datachoosepassenger["FullName"] = itemPass.FullName;
      datachoosepassenger["c_name"] = itemPass.c_name;
      datachoosepassenger["u_imageurl"] = itemPass.u_imageurl;
      datachoosepassenger["u_telephone"] = itemPass.u_telephone;
      datachoosepassenger["remark"] = itemPass.remark;
      datachoosepassenger["tsl_pickup_id"] = itemPass.tsl_pickup_id;
      datachoosepassenger["pickup"] = itemPass.pickup;
      datachoosepassenger["tsl_drop_id"] = itemPass.tsl_drop_id;
      datachoosepassenger["drop"] = itemPass.drop;
      datachoosepassenger["tsj_picup_time"] = itemPass.tsj_picup_time;
      datachoosepassenger["tsj_drop_time"] = itemPass.tsj_drop_time;

      //Add
      datachoosepassenger["SafetyTrainingDayLeft"] =
        itemPass.SafetyTrainingDayLeft;
      datachoosepassenger["MedicalCheckupDayLeft"] =
        itemPass.MedicalCheckupDayLeft;

      this.listPassenger[index] = datachoosepassenger;
      this.SumDataPassenger();
    },
    //Add
    GotoStepDialog2() {
      if (this.arraychoospassenger[0].FullName != "") {
        this.stepdialog = 2;
      }
    },

    async SelectListVehicle() {
      try {
        var q = {};
        q.retire = 0;
        var res = await feathersClientVan.service("vehicle").find({ query: q });
        this.itemsListVehicle = res.data;
        this.vehicle = this.itemsListVehicle[0];
      } catch (error) {
        console.log(error);
        alert("ไม่สามารถขอข้อมูลจาก server ได้");
      }
    },
    async SelectListDriver() {
      try {
        var q = {};
        q.retire = 0;
        var res = await feathersClientVan.service("driver").find({ query: q });
        this.itemsListDriver = res.data;
        this.driver = this.itemsListDriver[0];
      } catch (error) {
        console.log(error);
        alert("ไม่สามารถขอข้อมูลจาก server ได้");
      }
    },

    SelectVehicleAndDriverItem(item, index) {
      this.dialogEvent2 = true;
      this.stepdialog2 = 2;
      this.chooseItemPassengerIndex = index;

      if (item.v_id != null) {
        this.vehicle = this.itemsListVehicle.filter(
          (x) => x.v_id == item.v_id
        )[0];
      } else {
        this.vehicle = this.itemsListVehicle[0];
      }

      if (item.d_id != null) {
        this.driver = this.itemsListDriver.filter(
          (x) => x.d_id == item.d_id
        )[0];
      } else {
        this.driver = this.itemsListDriver[0];
      }
    },

    async SaveVehicleAndDriver() {
      this.dialogEvent2 = false;
      //Add Save to DB
      var dataUpdate = {
        v_id: this.vehicle.v_id,
        v_license_plate: this.vehicle.v_license_plate,
        d_id: this.driver.d_id,
        d_name: this.driver.d_name,
      };
      await this.UpdateVehicleAndDriverTodb(
        this.listPassenger[this.chooseItemPassengerIndex].tsj_id,
        dataUpdate
      );

      await this.DataPassengerFromDB(this.transportId);
    },

    async UpdateVehicleAndDriverTodb(idtransportjob, dataUpdate) {
      try {
        await feathersClientUOA
          .service("transportjob")
          .patch(idtransportjob, dataUpdate);
      } catch (error) {
        console.log(error);
      }
    },

    async SearchPassenger() {
      try {
        if (this.search != "") {
          const q = {};
          if (this.userdata.r_id != 4 && this.userdata.r_id != 8) {
            q.o_id = this.userdata.o_id;
          }
          q.r_id = 3;
          q.u_firstname = { $like: this.search + "%" };

          let res = await feathersClientUOA
            .service("viewuser")
            .find({ query: q });
          this.userItem = res.data;
        }
      } catch (err) {}
    },
  },
};
</script>

<style >
</style>